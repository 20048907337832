export const SOCKETIO_URL = "pusher.com";
export const PUSHER_KEY = 'cf41d68709e66c0e645b';
export const PUSHER_REGION  ='mt1';
export const PUSHER_APP  ='autokorea-aws';
export const PUSHER_APP_SECRET  ='8f0e335a0dfb69d95dbf';
export const PUSHER_CHANNEL  ='autokorea-aws';
export const PUSHER_ID = '1861355';
// export const API_URL = "https://api.aksystem.co.za"; // Production Server
export const API_URL = "https://api.akss.co.za"; // AWS Production Server
// export const API_URL = "http://127.0.0.1:8000"; // Local Dev Server
// export const API_URL = "https://ak-api.sinappsus.online"; // Sinapsus Dev Server
// export const API_URL = "https://wapsystem-api.akerp.co.za"; // Madagascar Server
// export const OPEN_AI_API_KEY = "sk-proj-v1ra1PuEfiul9ToHZEVQT3BlbkFJx3VfKkUPKr193IJFjxDX";